body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: #fff;
}

a,
a:link,
a:visited{
  color: #fff;

}

a:hover{
  text-decoration: none;
}

ul,
ul li{
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul{
  display: flex;
  justify-content: flex-end;
}

nav ul li{
  padding: 5px 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 15px 0;

}

.content-container{
  padding: 10px 50px;
  text-align: center;
}


footer{
  height: 100px;
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 25px 0 50px 0;
  background-color: #07070E;
}

footer img{
  width: 100px;
  margin-top: 15px;
}
